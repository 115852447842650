import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import './PayPage.css';
import {string_to_slug} from '../../Utilities/utilities';
import paypal from './Paypal.svg';

class PayPage extends Component {	
	constructor() {
        super();
        this.state = {
        	email:'',
        	date:'',
        	name:'',
        	address:'',
        	phone:'',
        	CardText:'',
        	AdicionalNotes:''
        };               
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = e => {
    	this.setState({[e.target.name]: e.target.value})
    }

	componentDidMount() {		
		window.scrollTo(0, 0);
	}

	handleSubmit(event) {			
		event.preventDefault();
		const Id = this.props.Data.find((item)=> string_to_slug(item.ProductTitle) === this.props.match.params.id).id;
		const {id, ProductTitle, price} = this.props.Data[(parseInt(Id) - 1) % this.props.Data.length];
	    const {email, date, name, address, phone, CardText, AdicionalNotes} = this.state;
	    /*const {
	      REACT_APP_PAYPAL_EMAIL,
	    } = this.props.env;*/	
	    let REACT_APP_PAYPAL_EMAIL = 'arielsvn.pp@gmail.com';

	    fetch('https://afternoon-taiga-64112.herokuapp.com/sendInfo', {
			'method': 'post',
			'headers': {'Content-Type': 'application/json'},
			body: JSON.stringify({
				email: email,
	        	date: date,
	        	name: name,
	        	address: address,
	        	phone: phone,
	        	CardText: CardText,
	        	AdicionalNotes: AdicionalNotes,
	        	id: id, 
	        	ProductTitle: ProductTitle, 
	        	price: price
			})
		})
		.then(() => {
	    	window.location.href=`https://www.paypal.com/cgi-bin/webscr?cmd=_xclick&
								business=${REACT_APP_PAYPAL_EMAIL}&
								item_name=${ProductTitle}&
								item_number=${id}&
								amount=${price + 5}&
								return=https://habanaflores.netlify.com?status=ok&
								cancel_return=https://habanaflores.netlify.com?status=cancel" target="_click`;
			}
		)
		.catch(
			console.log('Something wrong hapened')
		)
	  }

	render() {	
		const Id = this.props.Data.find((item)=> string_to_slug(item.ProductTitle) === this.props.match.params.id).id;			
		const {ProductTitle, price, images} = this.props.Data[(parseInt(Id) - 1) % this.props.Data.length];
		const path = images[0];
		return (	
			<div style={{margin:'80px'}} className='w3-container center'>
				<div style={{width:'800px'}} className='w3-container topMedium' >							
					<div className='w3-col l6' style={{display:'flex', justifyContent: 'center'}}>
						<form style={{minWidth:350}} onSubmit={this.handleSubmit} className="w3-container w3-white w3-text-grey w3-margin w3-border">
							<h2 style={{marginBottom:'30px'}} className="w3-center">Completar envío</h2>					 
							<div className="w3-row w3-section">
							  <div className="w3-col" style={{width:'50px'}}><i className="w3-xxlarge fa fa-envelope-o"></i></div>
							    <div className="w3-rest">
							      	<input className="w3-input w3-border" name="email" type="text" placeholder="Correo electrónico" onChange={this.handleChange} required/>
							    </div>
							</div>

							<div className="w3-row w3-section">
							  <div className="w3-col" style={{width:'50px'}}><i className="w3-xxlarge fa fa-calendar-o"></i></div>
							    <div className="w3-rest">
							      <input className="w3-input w3-border" name="date" type="text" placeholder="Fecha de entrega" onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.handleChange} required/>
							    </div>
							</div>

							<p className='w3-text-grey w3-left-align' style={{marginBottom:'30px', marginTop:'30px'}}>Datos de la persona a la que quieres hacer el envío</p>

							<div className="w3-row w3-section">
							  <div className="w3-col" style={{width:'50px'}}><i className="w3-xxlarge fa fa-user"></i></div>
							    <div className="w3-rest">
							      <input className="w3-input w3-border" name="name" type="text" placeholder="Nombre" onChange={this.handleChange} required/>
							    </div>
							</div>

							<div className="w3-row w3-section">
							  <div className="w3-col" style={{width:'50px'}}><i className="w3-xxlarge fa fa-map-marker"></i></div>
							    <div className="w3-rest">
							      <input className="w3-input w3-border" name="address" type="text" placeholder="Dirección" onChange={this.handleChange} required/>
							    </div>
							</div>						

							<div className="w3-row w3-section">
							  <div className="w3-col" style={{width:'50px'}}><i className="w3-xxlarge fa fa-phone"></i></div>
							    <div className="w3-rest">
							      <input className="w3-input w3-border" name="phone" type="text" placeholder="Teléfono" onChange={this.handleChange} required/>
							    </div>
							</div>

							<div className="w3-row w3-section">
							  <div className="w3-col" style={{width:'50px'}}><i className="w3-xxlarge fa fa-pencil"></i></div>
							    <div className="w3-rest">						      
							    	<textarea className="w3-input w3-border" name="CardText" style={{resize:'none'}} placeholder="Texto en la Targeta" onChange={this.handleChange}></textarea>
							    </div>
							</div>

							<div className="w3-row w3-section">
							  <div className="w3-col" style={{width:'50px'}}><i className="w3-xxlarge fa fa-pencil"></i></div>
							    <div className="w3-rest">						      
							    	<textarea className="w3-input w3-border" name="AdicionalNotes" style={{resize:'none'}} placeholder="Notas especiales para el pedido" onChange={this.handleChange}></textarea>
							    </div>
							</div>
							
							<p className="w3-right">												
							<button className="w3-btn w3-section w3-ripple" style={{backgroundColor: '#A52A2A'}}> ENVÍALO YA </button>
							</p>
						</form>
					</div>
					<div className='w3-col l6' style={{display:'flex', justifyContent: 'center', marginBottom:'50px'}}>
						<div className="" style={{paddign:'0px'}}>
							<h2 style={{marginBottom:'30px', fontWeight: 'bold', color:'green'}} className="w3-center">{ProductTitle}</h2>
							<img src={path} alt='' style={{width:'100%', height: 'auto'}}/>		
							<div className='w3-white' style={{Width: '350px'}}>
								<table className="w3-table w3-border w3-bordered" style={{marginTop:'20px'}} >
									<tbody>
										<tr>
										<th>Producto</th>
										<th>Total</th>
										</tr>
										<tr>
										<td>{ProductTitle}</td>
										<td>${price}</td>						      
										</tr>
										<tr>
										<th>Envío</th>
										<th>$5.00</th>						      
										</tr>
										<tr>
										<td>Total</td>
										<td>${price + 5}</td>						      
										</tr>
									</tbody>
								</table>
								<div style={{display:'flex',flexDirection:'row', marginTop:'5px'}}>
							  		<img style={{marginRight:'5px', height:'60px', width:'60'}} className='w3-border w3-round' src={paypal} alt='paypal ico'/>
									<div>
										<label className='w3-text-red'>
											<i style={{marginRight:'2px'}} className="fa fa-dot-circle-o" aria-hidden="true"></i>
											Paypal
										</label>							
										<pre style={{margin:'5px 0px 0px 15px', fontSize:'12px'}} className='w3-text-grey'>
											Puedes pagar con tu targeta de crédito<br/>
											si no tienes una cuenta PayPal
										</pre>
									</div>							    
								</div>								
							</div>				 					
						</div>
					</div>
				</div>
			</div>
		);
	}
	onFocus(e){
		e.currentTarget.type = 'date';
	}
	onBlur(e){
		e.currentTarget.type = 'text';
		e.currentTarget.placeholder = 'Fecha de entrega';
	}
}

PayPage.propTypes = {
  env: PropTypes.object.isRequired
};

export default withRouter(PayPage);