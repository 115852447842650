/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Logo from '../Logo/Logo'
import './Navigation.css';

class Navigation extends Component {
	componentDidMount() {
		document.addEventListener("scroll", ()=>{
			var x = document.getElementById("navDemo");
			if (x.className.indexOf("w3-show") === -1) {
				return;
			} else { 
				x.className = x.className.replace(" w3-show", "");
				document.getElementById("myOverlay").style.display = "none";
			} 
		});
	}	

	render() {
		return (
			<div id="home" style={{position:"relative", zIndex:100}}>
				<div className="w3-top w3-border">
					<ul className="w3-navbar center">						
						<li className="w3-hide-large w3-left menuSmall">
							<a className="w3-padding-large w3-left menuButton" href="javascript:void(0)" onClick={this.myFunction} title="Toggle Navigation Menu"><i className="fa fa-bars"></i></a>
							<p className="hf w3-right">HabanaFlores</p>
						</li>
						<li className="large-navbarli2 w3-hide-small w3-hide-medium menuItem" style={{fontSize:'20px'}}><a href='/#home'>INICIO</a></li>					  					  												
						<li className="large-navbarli w3-hide-small w3-hide-medium menuItem" style={{fontSize:'20px'}}><a href='/#flowers'>RAMOS DE FLORES</a></li>						
						<li className='w3-hide-small w3-hide-medium' style={{marginTop:'8px'}}><Logo/></li>
						<li className="large-navbarli w3-hide-small w3-hide-medium menuItem" style={{fontSize:'20px'}}><a href='/#aboutUs'>QUIENES SOMOS</a></li>
						<li className="large-navbarli3 w3-hide-small w3-hide-medium menuItem" style={{fontSize:'20px'}}><a href='/#contact'>CONTACTO</a></li>												
					</ul>					
				</div>				
				<div id="navDemo" style={{zIndex:'3'}} className="w3-hide w3-top">
				  	<ul className="w3-navbar w3-left-align">					  	
				  		<li className="w3-hide-large w3-left menuSmall">
					    	<a className="w3-padding-large w3-left menuButton" href="javascript:void(0)" onClick={this.myFunction} title="Toggle Navigation Menu"><i className="fa fa-bars"></i></a>
							<p className="hf w3-right">HabanaFlores</p>
					  	</li>
				    	<li className="w3-padding" onClick={this.myFunction} style={{marginTop:'50px'}}><a href='/#home'>INICIO</a></li>
				    	<li className="w3-padding" onClick={this.myFunction} style={{marginTop:'0px'}}><a href='/#flowers'>RAMOS DE FLORES</a></li>
				    	<li className="w3-padding" onClick={this.myFunction}><a href='/#aboutUs'>QUIENES SOMOS</a></li>
				    	<li className="w3-padding" onClick={this.myFunction}><a href='/#contact'>CONTACTO</a></li>
				  	</ul>
				</div>	
				<div className="w3-overlay w3-hide-large" onClick={this.myFunction} style={{cursor:'pointer'}} title="close side menu" id="myOverlay"></div>				
			</div>
		);
	}

	myFunction() {
    	var x = document.getElementById("navDemo");
    	if (x.className.indexOf("w3-show") === -1) {
			x.className += " w3-show";
			document.getElementById("myOverlay").style.display = "block";
    	} else { 
			x.className = x.className.replace(" w3-show", "");
			document.getElementById("myOverlay").style.display = "none";
    	}
	}
}

export default Navigation;