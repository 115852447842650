import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {string_to_slug} from '../../Utilities/utilities';
import './Card.css';

class Card extends Component {		
	render() {		
		const {ProductTitle, descriptionSmall, price, images} = this.props.product;
		const path = images[0];
		const pathname = '/flores/' + string_to_slug(ProductTitle);
		return (									
			        <div className="w3-card-8">
			        	<div className="w3-display-container w3-hover-opacity">
							<Link to={{pathname:pathname}}>
								<img src={path} alt='' className="imgcard"/>
							</Link>						    						    
						</div>			          	
			          	<div className='w3-container' style={{backgroundColor:'#fcfae8', padding:'16px'}}>
				          	<h3 className='green title w3-ripple' style={{fontWeight:'bold', marginTop:'-10px'}}><Link style={{ textDecoration: 'none' }} to={{pathname:pathname}}>{ProductTitle}</Link></h3>
				          	<p className='smallDescription grey' style={{height:'50px'}}>{descriptionSmall}</p>
				          	<p className="w3-left green w3-small price" style={{margin:'0px', padding:'0px', marginTop:'5px', fontWeight:'bold'}}>${price.toFixed(2)}</p>				          	
							<p className="w3-right w3-btn w3-border w3-text-white w3-green w3-small" style={{margin:'0px', padding:'5px'}}>
								<Link style={{ textDecoration: 'none' }} to={{pathname:pathname}}>VER DETALLES</Link>
				          	</p>         
			          	</div>			          	
		          	</div>	          	
		);
	}
}

export default Card;