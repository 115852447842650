import React, { Component } from 'react';
import './StartImg.css';
import flower from '../../Images/Flores.jpg';

class StartImg extends Component {
    render() {
        return (
            <div className="w3-display-container w3-text-white" style={{marginTop:'50px', zIndex:3}}>
					<img className='img' style={{width:'100%', backgroundSize:'cover'}} src={flower} alt=''/> 
					<div className="w3-padding w3-display-topmiddle margin" style={{textAlign:'center'}}>
						<h3 className='smallSize'>ENCARGUE SUS FLORES<br/>Y ENVÍELAS A LA HABANA</h3>
						<p className="w3-opacity w3-center w3-hide-small w3-hide-medium"><i>
				            Para bodas, cumpleaños, sorprender a sus seres queridos con un punto de cariño. 
				            Ramos personalizados de variadas flores cubanas. 
				            Ponemos la calidad en cada flor.</i>
				        </p>
					</div>
				</div>
        );
    }
}

export default StartImg;