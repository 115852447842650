import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
	constructor() {
        super();
        this.state = {
        	Email:'',
        	msg:'',        
        };               
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = e => {
    	this.setState({[e.target.name]: e.target.value})
	}
			
	render() {
		return (
			<footer id='contact' className="w3-container w3-padding-32 w3-theme-d1" style={{width:'100%'}}>
				<div className="w3-container w3-content w3-padding" style={{maxWidth:'1200px'}}>                    
                    <div className="w3-row w3-padding-32 w3-center">
                        <div className="w3-col l4 w3-padding w3-text-white w3-center" style={{minWidth:'300px', marginTop:'0px'}}>
							<div className='center'>
							<p className="" style={{textAlign:'left',marginTop:'0px'}}>
                            <i className='w3-opacity' style={{paddingBottom:'15px', fontSize:'12px'}}>Contáctenos para cualquier queja o sujerencia.<br/>Comunícate con nosotros. Estamos a su servicio.</i><br/><br/>                            
                            <i className="fa fa-phone" style={{width:'30px'}}></i> Phone: +00 151515<br/>
                            <i className="fa fa-envelope" style={{width:'30px'}}></i> amigos@habanaflores.com<br/>
                            <i className="fa fa-globe"  style={{width:'30px'}}></i> <a href="http://habanaflores.netlify.com">www.habanaflores.com</a><br/>
							</p>
							</div>
                        </div>
                        <div className="w3-col l4 w3-padding w3-center" style={{minWidth:'300px'}}>
							<div className='center'>
                            <form style={{width:'295px'}} onSubmit={this.handleSubmit} target="_blank">
                            <div className="" style={{margin:'0px 0px 8px 0px'}}>                          
                                <input className="w3-input w3-border" name="Email" type="text" placeholder="Correo electrónico" onChange={this.handleChange} required/>
                            </div>
                            <textarea style={{height:'120px'}} className="w3-input w3-border" type="text" placeholder="Mensage" required name="msg" onChange={this.handleChange}/>
                            <button className="w3-btn w3-section w3-ripple w3-right w3-border" style={{backgroundColor: '#A52A2A'}} type="submit">ENVIAR</button>
                            </form>
							</div>
                        </div>
                        <div className="w3-col l4 w3-center" style={{padding:'8px 16px 8px 16px'}}>		
							<div className='center'>
								<div style={{width:'300px'}} id='fb_button' className="fb-page" data-href="https://www.facebook.com/habanaflores.like/?epa=SEARCH_BOX" 
									data-width="300" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
									<blockquote cite="https://www.facebook.com/habanaflores.like/?epa=SEARCH_BOX" className="fb-xfbml-parse-ignore">
										<a href="https://www.facebook.com/habanaflores.like/?epa=SEARCH_BOX">Habana Flores</a>
									</blockquote>
								</div>
							</div>							
                        </div>
                    </div>
                </div>
				<div className='w3-center'>
					<h4 className="w3-text-white">Copyright © 2015 Habana Flores</h4>
					<p className="w3-text-white">Para cualquier duda o sugerencia contáctenos al correo <a className='w3-link' href="mailto:amigos@habanaflores.com">amigos@habanaflores.com</a></p>
					<a className="w3-btn-floating w3-teal" style={{margin:'5px'}} href="https://www.facebook.com/habanaflores.like/" title="Facebook"><i className="fa fa-facebook"></i></a>
					<a className="w3-btn-floating w3-teal" style={{margin:'5px'}} href="#" title="Twitter"><i className="fa fa-twitter"></i></a>			  	
					<a className="w3-btn-floating w3-teal" style={{margin:'5px'}} href="#" title="Instagram"><i className="fa fa-instagram"></i></a>     				
				</div>
			</footer>
		);		
	}
	handleSubmit(event) {
		event.preventDefault();
		const {Email, msg} = this.state;
		fetch('https://afternoon-taiga-64112.herokuapp.com/sendmsg', {
			'method': 'post',
			'headers': {'Content-Type': 'application/json'},
			body: JSON.stringify({
				Email: Email,
	        	msg: msg	        	
			})
		})
		.then(() => {			
			this.setState({Email: '', msg: ''});
			document.getElementsByName("Email")[0].value = '';
			document.getElementsByName("msg")[0].value = '';
			}
		)
		.catch(console.log)
	}
}

export default Footer;