import React, { Component } from 'react';
import Logo from '../Logo/Logo'

class ConfirmationMSG extends Component {		
	render() {		
		return (
			<div id="confMSG" className="w3-modal w3-center" style={{display:'block'}} onClick={() => document.getElementById('confMSG').style.display='none'}>
			    <div className="w3-modal-content w3-card-8" style={{width:'400px'}}>
			      <header className="w3-container w3-white">			
					<Logo/>										
			      </header>
			      <div className="w3-container brown" style={{fontFamily: 'Roboto Mono Medium'}}>
			        <p >Su envío ha sido procesado con éxito</p>
			        <p>Se le mantendra al tanto de todo el proceso</p>
			      </div>
			      <footer className="w3-container brownBackground w3-text-white">
			        <p>Habana Flores</p>
			      </footer>
			    </div>			    
			</div>        
		);
	}
}

export default ConfirmationMSG;