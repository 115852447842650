/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Card from '../Card/Card';
import paginationStyles from './CardsContainer.module.css';

class CardsContainer extends Component {	
	
	constructor(props){
		super(props);
		this.state = {
			displayCards: this.getDisplayCards(props.cardsData),
			pagination: this.getPagination(props.cardsData.length, 1),
			active: 1,
		}
		this.moveTo = this.moveTo.bind(this)
		
	}

	getDisplayCards(cards, nStart=0, nEnd=12){
		return cards.filter((e, i) => (i >= nStart && i < nEnd))
					.map((e)=> (<div className="w3-col l3 m6 w3-margin-bottom w3-margin-top">
						<Card product={e}/>
					</div>))
	}

	getPagination(n,activePag){
		let pags = [];
		for (let index = 0; index < n/12; index++) {
			pags[index]=index+1;
			
		}
		return pags.map((num)=> {
			return <PagNumber 
						number={num} 
						active={activePag} 
						onPagChange={() => this.moveTo(num)}/>;
		});
	}

	moveTo(n){
		if (n <= 0 || n > this.state.pagination.length) return
		this.setState((state, props)=> ({
			displayCards: this.getDisplayCards(props.cardsData, (n-1)*12, n*12),
			pagination: this.getPagination(props.cardsData.length, n),
			active: n,
		}));
		
		document.getElementById("flowers").scrollIntoView();
	}

	render() {
		return (			
			<div id='flowers' style={{backgroundColor:'#f9f5d2'}}>
    			<div className="w3-container w3-content w3-padding-64" style={{maxWidth:'1000px'}}>
    				<h2 className="w3-wide w3-center brown">ARREGLOS FLORALES</h2>
      				<p className="w3-opacity w3-center"><i>Encargue sus flores y envíelas a La Habana</i></p>
		            <div style={{Width:'90%'}} className='w3-row-padding'>
		            	{this.state.displayCards}
		            </div>
		            <div style={{display: 'flex', justifyContent: 'center'}}>
						<div className={paginationStyles.pagination}>
							<a href="javascript:void(0)" onClick={() => this.moveTo(this.state.active-1)}>&laquo;</a>
							{this.state.pagination}
							<a href="javascript:void(0)" onClick={() => this.moveTo(this.state.active+1)}>&raquo;</a>
						</div>
					</div>
		        </div>
        	</div>
		);
	}	
}

class PagNumber extends Component {
	constructor(props){
		super(props)
		this.handlePageChange = this.handlePageChange.bind(this)
	}
	handlePageChange(){
		this.props.onPagChange()
	}
	render(){
		const {number,active} = this.props;
		return <a href="javascript:void(0)" 
				className={(active === number)?paginationStyles.active:''} 
				onClick={this.handlePageChange}>{number}</a>;
	}
}

export default CardsContainer;