import React, { Component } from 'react';
import Card from '../Card/Card';
import {string_to_slug} from '../../Utilities/utilities';
import {Link, withRouter} from 'react-router-dom';
import {
	FacebookShareButton,
	FacebookIcon,
	TwitterShareButton,
	TwitterIcon,
  } from 'react-share';
import './FlowerBunchDetails.css';

class FlowerBunchDetails extends Component {	
	constructor(props){
		super(props);
		this.state = {
			slideIndex: 1,
		}
	}
	componentDidMount() {		
		window.scrollTo(0, 0);
		console.log('dwsjhedsee');
		if(document.getElementById('flcont') != null)
			this.showDivs(this.state.slideIndex);
	}

	getOtherFlowers(mainBunch){
		const pathmain = mainBunch.images[0];
		var pathfl1 = mainBunch.images[1];
		var pathfl2 = mainBunch.images[2];
				
		if(mainBunch.images.length === 3) {
			return(
				[
				<div id='flcont' style={{display:'flex', flexDirection:'column', justifyContent:'center', marginRight:'10px'}}>	
					<img src={pathmain} alt='foto' style={{width:'50px', margin:'5px'}} className="w3-button demo w3-circle filter-inactive" onClick={() => this.currentDiv(1)}/>
					<img src={pathfl1} alt='foto' style={{width:'50px', margin:'5px'}} className="w3-button demo w3-circle filter-inactive" onClick={() => this.currentDiv(2)}/>
					<img src={pathfl2} alt='foto' style={{width:'50px', margin:'5px'}} className="w3-button demo w3-circle filter-inactive" onClick={() => this.currentDiv(3)}/>
				</div>,
				<div>
					<img alt='foto' className="mySlides" src={pathmain} style={{width:'250px'}}/>
					<img alt='foto' className="mySlides" src={pathfl1} style={{width:'250px'}}/>
					<img alt='foto' className="mySlides" src={pathfl2} style={{width:'250px'}}/>
				</div>
				]
			);
		}
		else {
			return(
				[
					null,
					<img src={pathmain} alt='' style={{width:'300px'}}/>					
				]
			);
		}		
	}

	ImageExist(url) 
	{
		const img = new Image();
		img.src = url;
		return img.height !== 0;
	}

	componentDidUpdate() {
		window.scrollTo(0, 0);		
		console.log('dwsjhedsee');
		if(document.getElementById('flcont') != null)
			this.showDivs(this.state.slideIndex);
	}
	
	getBunchDetailsData = (Data, productName) => {
		const Id = Data.find((item)=> string_to_slug(item.ProductTitle) === productName).id;         
        const length = Data.length;
        let a = 
        [
            Data[(Id - 1) % length],
            Data[Id % length],
            Data[(Id + 1) % length],
            Data[(Id + 2) % length],
            Data[(Id + 3) % length]
        ]
        return a;    
	}

	render() {				
		const Data = this.getBunchDetailsData(this.props.Data, this.props.match.params.id);
		const mainBunch = Data[0];
		const pahtname='/checkout/' + string_to_slug(mainBunch.ProductTitle);
		const shareUrl=window.location.href;
		const fl = this.getOtherFlowers(mainBunch);
		const extraimgbtns = fl[0];
		const extraimgs = fl[1];		
		return (
			<div>
    			<div className="w3-content w3-padding-64" style={{maxWidth:'1000px'}}>    				
		            <div style={{Width:'90%'}} className='w3-row-padding'>
		            	<div className="w3-col l6 m6 w3-margin-bottom w3-margin-top center">						
							{extraimgbtns}	
							{extraimgs}		                	
		                </div>
		                <div className="w3-col l6 m6 w3-margin-bottom w3-margin-top center">
		                	<div style={{margin:'10px'}}>
		                		<h3 className='green'><b>{mainBunch.ProductTitle}</b></h3>
		                		<p className='grey w3-margin-bottom' style={{maxWidth:'500px'}}>{mainBunch.descriptionLarge}</p>
		                		<div>
		                			<div className='greyBackground' style={{height:'0.5px'}}></div>
		                			<p className="green" style={{fontSize:'24px', margin:'10px 0px 10px 0px'}}>${mainBunch.price.toFixed(2)}</p>
				          			
									<div>
										<p className="w3-btn w3-border w3-text-grey w3-white w3-small" style={{float: 'left', marginTop:'0px'}}>
											<Link style={{ textDecoration: 'none' }} to={{pathname:pahtname}}>ENVIAR AHORA</Link>											
										</p>
										<div style={{float: 'right', marginLeft:'10px'}}>
										<FacebookShareButton
												url={shareUrl}
												className="Demo__some-network__share-button">
												<FacebookIcon
												size={32}
												round />
										</FacebookShareButton>										
										</div>
										<div style={{float: 'right'}}>
											<TwitterShareButton
													url={shareUrl}
													className="Demo__some-network__share-button">
													<TwitterIcon
													size={32}
													round />
											</TwitterShareButton>
										</div>
									</div>
		                		</div>
		                	</div>		                	
		                </div>
		            </div>
		            {/* <div className='greyBackground' style={{height:'0.5px'}}></div> */}
		            <div className="w3-container w3-content w3-padding-64">
			            <div style={{Width:'90%'}} className='w3-row-padding'>
			            	<div className="w3-col l3 m6 w3-margin-bottom w3-margin-top">
			                	<Card product={Data[1]}/>
			                </div>
			                <div className="w3-col l3 m6 w3-margin-bottom w3-margin-top">
			                	<Card product={Data[2]}/>
			                </div>
			                <div className="w3-col l3 m6 w3-margin-bottom w3-margin-top">
			                	<Card product={Data[3]}/>		                
			                </div>
							<div className="w3-col l3 m6 w3-margin-bottom w3-margin-top">
			                	<Card product={Data[4]}/>		                
			                </div>
			            </div>			            
		            </div>
		        </div>
        	</div>	        
		);
	}

	currentDiv(n) {
		this.setState({slideIndex: n});
		this.showDivs(this.state.slideIndex);
	}

	showDivs(n) {
		var i;
		var x = document.getElementsByClassName("mySlides");
		var dots = document.getElementsByClassName("demo");
		if (n > x.length) {this.setState({slideIndex:1})}    
		if (n < 1) {this.setState({slideIndex:x.length})}
		for (i = 0; i < x.length; i++) {
			x[i].style.display = "none";  
		}
		for (i = 0; i < dots.length; i++) {
			dots[i].className = dots[i].className.replace(" filter-active", "");
		}
		x[this.state.slideIndex-1].style.display = "block";  
		dots[this.state.slideIndex-1].className += " filter-active";
	}
}

export default withRouter(FlowerBunchDetails);