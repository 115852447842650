import React, { Component } from 'react';
import aboutUs from '../../Images/AboutUs.png';
import location from '../../Images/Location.png';
import productLabel from '../../Images/ProductLabel.png';
import camara from '../../Images/Camara.png';
import discount from '../../Images/Discount.png';

class AboutUs extends Component {		
	render() {
		return (
				<div className="w3-white" id="aboutUs">					
					<div className="w3-container w3-content w3-padding-64" style={{maxWidth:'1000px'}}>
						<h3 className="w3-wide w3-center brown" style={{fontWeight:'bold'}}>SOMOS HABANA FLORES</h3>
						<div  className='w3-container' style={{display: 'flex', justifyContent:'center'}}>
							<img className='w3-image' src={aboutUs} alt=''/>
						</div>
				        <p  className="w3-center">
							Somos una familia proveniente de San Antonio de los Baños, La Habana, Cuba 
							con una tradición en el trabajo con arreglos florales por encargo.
			          	</p>
			          	<div style={{Width:'90%'}} className='w3-row-padding'>
			          		<div className="w3-col l3">								        			
							        <div className="w3-display-container w3-center">							        	
										<img src={location} alt=''/>						    
									</div>			          	
							        <div className='w3-container'>
								        <p className="w3-center">
											Somos una familia proveniente de San Antonio de los Baños, La Habana, Cuba 
											con una tradición en el trabajo con arreglos florales por encargo.
						          		</p>         
							        </div>			          			          				
	          				</div>
	          				<div className="w3-col l3">								        			
							        <div className="w3-display-container w3-center">							        	
										<img src={productLabel} alt=''/>						    
									</div>			          	
							        <div className='w3-container'>
								        <p className="w3-center">
											Somos una familia proveniente de San Antonio de los Baños, La Habana, Cuba 
											con una tradición en el trabajo con arreglos florales por encargo.
						          		</p>         
							        </div>			          			          				
	          				</div>
	          				<div className="w3-col l3">								        			
							        <div className="w3-display-container w3-center">							        	
										<img src={camara} alt=''/>						    
									</div>			          	
							        <div className='w3-container'>
								        <p className="w3-center">
											Somos una familia proveniente de San Antonio de los Baños, La Habana, Cuba 
											con una tradición en el trabajo con arreglos florales por encargo.
						          		</p>         
							        </div>			          			          				
	          				</div>
	          				<div className="w3-col l3">								        			
							        <div className="w3-display-container w3-center">							        	
										<img src={discount} alt=''/>						    
									</div>			          	
							        <div className='w3-container'>
								        <p className="w3-center">
											Somos una familia proveniente de San Antonio de los Baños, La Habana, Cuba 
											con una tradición en el trabajo con arreglos florales por encargo.
						          		</p>         
							        </div>			          			          				
	          				</div>			                             
		            	</div>	
		            	<h4 className="w3-wide w3-center w3-text-red" style={{fontWeight:'bold'}}><i>Nuestra máxima es presentarle un servicio de calidad y nos commplace cubrir sus expectativas</i></h4>		          															
		          	</div>		          	
	          	</div>	        
		);
	}

	modal = () => {
		document.getElementById('modal01').style.display='block';
	}

	quitModal = () => {
		document.getElementById('modal01').style.display='none';
	}
}

export default AboutUs;