import React, { Component } from 'react';
import Navigation from './Components/Navigation/Navigation';
import CardsContainer from './Components/CardsContainer/CardsContainer';
import AboutUs from './Components/AboutUs/AboutUs';
import Footer from './Components/Footer/Footer';
import StartImg from './Components/StartImg/StartImg';
import FlowerBunchDetails from './Components/FlowerBunchDetails/FlowerBunchDetails'
import PayPage from './Components/PayPage/PayPage';
import ConfirmationMSG from './Components/ConfirmationMSG/ConfirmationMSG';
import PropTypes from 'prop-types';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import './App.css';
import database from './data/flowers'

const initialState = {    
    status: 'none',
}

class App extends Component {
    gup = (name, url) => {
        if (!url) url = window.location.href;
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/,"\\\]");
        var regexS = "[\\?&]" + name+"=([^&#]*)";
        var regex = new RegExp(regexS);
        var results = regex.exec(url);
        return results === null ? null : results[1];        
    }
    
    constructor() {
        super();
        this.state = initialState;        
        var url_string = window.location.href;     
        //var url_string = 'https://habanaflores.netlify.com?status=ok';
        var status = this.gup('status',url_string)
        if (status !== null)
        {            
            this.state['status'] = status;
            if (status === 'cancel')
            {
                fetch('https://afternoon-taiga-64112.herokuapp.com/clearInfo', {
                    'method': 'post',                                    
                })
            }
            if (status === 'ok')
            {
                fetch('https://afternoon-taiga-64112.herokuapp.com/send', {
                    'method': 'post',                                    
                })
            }
        }
        else
        {
            this.state['status'] = 'none';            
        }  
    }

    onPayProduct = (id) => {
        this.setState({productId: id});
        this.onRouteChange('payPage');
    }

    getCardsData = () => {
        return database.products.map((item) => {
            const {id, ProductTitle, descriptionSmall, price, images} = item;
            return {id,ProductTitle, descriptionSmall, price, images};
        }) 
    }

    render() {
        const {status} = this.state;        
        return (        
      <BrowserRouter>
        {   
            status === 'ok' ? <ConfirmationMSG/> : null
        }
        <Switch>
            <Route exact path="/">
                <Navigation/>
                <StartImg/>
                <CardsContainer cardsData = {this.getCardsData()} getProductDetails={this.getProductDetails}/>
                <AboutUs/>                            
            </Route>
            <Route path="/flores/:id">
                <Navigation />
                <FlowerBunchDetails Data={database.products} onPayProduct={this.onPayProduct}/>
            </Route>
            <Route path="/checkout/:id">
                <Navigation/>
                <PayPage Data={database.products} env={this.props.env}/>
            </Route>
            <Route path="*">                    
            <h1 className='w3-center'>Route not Found</h1>
            </Route>
        </Switch>
        <Footer/>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  env: PropTypes.object.isRequired
};

export default App;
