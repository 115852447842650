export default {
    products: [
        {
            id: 1,
            ProductTitle: 'Rosas y Lirios',
            descriptionLarge: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod' +
            'tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,' +
            'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo' +
            'consequat.',
            descriptionSmall: 'Ramo de 12 rosas y 12 lirios',
            price: 22.00,    
            images: [
                '/Flowers/flower_1.jpg',
                '/Flowers/flower_1_1.jpg',
		        '/Flowers/flower_1_2.jpg',
            ],        
        },
        {
            id: 2,
            ProductTitle: 'Mediterráneo1',
            descriptionLarge: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod' +
            'tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,' +
            'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo' +
            'consequat.',
            descriptionSmall: 'Ramo de rosas amarillas',
            price: 55.00,
            images: [
                '/Flowers/flower_2.jpg',            
            ],
        },
        {
            id: 3,
            ProductTitle: 'Mediterráneo2',
            descriptionLarge: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod' +
            'tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,' +
            'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo' +
            'consequat.',
            descriptionSmall: 'Ramo de rosas amarillas',
            price: 55.00,
            images: [
                '/Flowers/flower_3.jpg',            
            ],
        },
        {
            id: 4,
            ProductTitle: 'Mediterráneo3',
            descriptionLarge: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod' +
            'tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,' +
            'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo' +
            'consequat.',
            descriptionSmall: 'Ramo de rosas amarillas',
            price: 55.00,
            images: [
                '/Flowers/flower_4.jpg',            
            ],
        },
        {
            id: 5,
            ProductTitle: 'Mediterráneo4',
            descriptionLarge: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod' +
            'tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,' +
            'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo' +
            'consequat.',
            descriptionSmall: 'Ramo de rosas amarillas',
            price: 55.00,
            images: [
                '/Flowers/flower_5.jpg',            
            ],
        },
        {
            id: 6,
            ProductTitle: 'Mediterráneo5',
            descriptionLarge: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod' +
            'tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,' +
            'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo' +
            'consequat.',
            descriptionSmall: 'Ramo de rosas amarillas',
            price: 55.00,
            images: [
                '/Flowers/flower_6.jpg',            
            ],
        },
        {
            id: 7,
            ProductTitle: 'Mediterráneo6',
            descriptionLarge: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod' +
            'tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,' +
            'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo' +
            'consequat.',
            descriptionSmall: 'Ramo de rosas amarillas',
            price: 55.00,
            images: [
                '/Flowers/flower_7.jpg',            
            ],
        },
        {
            id: 8,
            ProductTitle: 'Mediterráneo7',
            descriptionLarge: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod' +
            'tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,' +
            'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo' +
            'consequat.',
            descriptionSmall: 'Ramo de rosas amarillas',
            price: 55.00,
            images: [
                '/Flowers/flower_8.jpg',            
            ],
        },
        {
            id: 9,
            ProductTitle: 'Mediterráneo8',
            descriptionLarge: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod' +
            'tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,' +
            'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo' +
            'consequat.',
            descriptionSmall: 'Ramo de rosas amarillas',
            price: 55.00,
            images: [
                '/Flowers/flower_9.jpg',            
            ],
        },
        {
            id: 10,
            ProductTitle: 'Mediterráneo9',
            descriptionLarge: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod' +
            'tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,' +
            'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo' +
            'consequat.',
            descriptionSmall: 'Ramo de rosas amarillas',
            price: 55.00,
            images: [
                '/Flowers/flower_10.jpg',            
            ],
        },
        {
            id: 11,
            ProductTitle: 'Mediterráneo10',
            descriptionLarge: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod' +
            'tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,' +
            'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo' +
            'consequat.',
            descriptionSmall: 'Ramo de rosas amarillas',
            price: 55.00,
            images: [
                '/Flowers/flower_11.jpg',            
            ],
        },
        {
            id: 12,
            ProductTitle: 'Mediterráneo11',
            descriptionLarge: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod' +
            'tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,' +
            'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo' +
            'consequat.',
            descriptionSmall: 'Ramo de rosas amarillas',
            price: 55.00,
            images: [
                '/Flowers/flower_12.jpg',            
            ],
        },
        {
            id: 13,
            ProductTitle: 'Mediterráneo12',
            descriptionLarge: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod' +
            'tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,' +
            'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo' +
            'consequat.',
            descriptionSmall: 'Ramo de rosas amarillas',
            price: 55.00,
            images: [
                '/Flowers/flower_13.jpg',            
            ],
        },
        {
            id: 14,
            ProductTitle: 'Mediterráneo13',
            descriptionLarge: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod' +
            'tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,' +
            'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo' +
            'consequat.',
            descriptionSmall: 'Ramo de rosas amarillas',
            price: 55.00,
            images: [
                '/Flowers/flower_14.jpg',            
            ],
        },
    ]
}